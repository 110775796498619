export default [{
    header: 'Doctores',
    icon: 'ActivityIcon',
    children: [{
        title: 'Lista',
        route: 'doctors',
        icon: 'ListIcon'
    }, {
        title: 'Agregar',
        route: 'doctors-create',
        icon: 'MousePointerIcon'
    }, ]
}];