import home from './home';
import booking from './booking';
import coupon from './coupon';
import survey from './survey';
import doctor from './doctor';
import notice from './notice';

export default [
    ...home,
    ...booking,
    ...coupon,
    ...doctor,
    ...survey,
    ...notice
];