export default [{
    header: 'Cupones de descuento',
    icon: 'PaperclipIcon',
    children: [{
        title: 'Lista',
        route: 'coupons',
        icon: 'ListIcon'
    }, {
        title: 'Agregar',
        route: 'coupons-create',
        icon: 'MousePointerIcon'
    }, ]
}];