export default [{
    header: 'Reservas',
    icon: 'CheckCircleIcon',
    children: [{
            title: 'Lista',
            route: 'bookings',
            icon: 'ListIcon'
        },
        {
            title: 'Agregar',
            route: 'booking-create',
            icon: 'MousePointerIcon'
        },
        {
            title: 'Calendario',
            route: 'bookings-calendar',
            icon: 'CalendarIcon'
        }
    ]
}];