export default [{
    header: 'Noticias',
    icon: 'SidebarIcon',
    children: [{
        title: 'Lista',
        route: 'notices',
        icon: 'ListIcon'
    }, {
        title: 'Agregar',
        route: 'notices-create',
        icon: 'MousePointerIcon'
    }, ]
}];